import { InternetServices } from "../internetServices/InternetServices";
import "./Wifi.css";
export const Wifi = () => {

    // const globalContent = JSON.parse(localStorage.getItem('globalContent') as string);
    // const hero = globalContent.wifi.hero;
    return (
        <div id="main-content" role="main">
            <section className="hero-component logged-in wifi-specific">
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                    <img style={{width: "100%"}} alt="Xfinity on campus" src="library/images/oncampus_logo.png" data-cookiecategory="1210"/>
                </div>
                <h2>Explore Internet Speeds</h2>
            </section>
            <InternetServices/>
        </div>
    );
};