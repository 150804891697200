import "./Help.css";
// import {useState, useEffect} from 'react';
export const Help = () => {
    const globalContent = JSON.parse(localStorage.getItem('globalContent') as string);
    const help = globalContent.help;
    // const [help, setHelp] = useState([]);
    // useEffect(() => {
    //     fetch('http://localhost:3001/help')
    //     .then(response => response.json())
    //     .then(data => setHelp(data));
    // }, []);
    return (
        
        <div id="main-content" role="main">
            <div className="cta-banner">
                <h2>{help.banner.header}</h2>
                {help.banner.text}
            </div>
            <div className="container">
	
  <section className="section knowledge-base">
   
      <ul className="blocks-list2">
              <li className="blocks-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="50%" viewBox="0 0 100 100" pointer-events="bounding-box"><defs><style>.cls-1{"{"}fill:none;{"}"}.cls-2{"{"}fill:#8b8b97;{"}"}</style></defs><g transform="translate(-10 0)" id="Layer_2" data-name="Layer 2"><path className="cls-2" d="M68.5,58.26a12.11,12.11,0,0,0-17.12,0l-2-2a15,15,0,0,1,21.19,0ZM60,59.8a7,7,0,0,1,5,2.06l2-2a9.91,9.91,0,0,0-14,0l2,2A7,7,0,0,1,60,59.8Zm0-10.2a17.1,17.1,0,0,1,12.17,5l2-2a20.09,20.09,0,0,0-28.42,0l2,2A17.1,17.1,0,0,1,60,49.6Zm-1.69,15A1.69,1.69,0,1,0,60,62.94,1.68,1.68,0,0,0,58.32,64.61ZM82,38.78a6.61,6.61,0,0,1,6.6,6.6V67.47a6.61,6.61,0,0,1-6.6,6.6H38a6.61,6.61,0,0,1-6.6-6.6V45.38a6.61,6.61,0,0,1,6.6-6.6H82m0-4.4H38a11,11,0,0,0-11,11V67.47a11,11,0,0,0,11,11H82a11,11,0,0,0,11-11V45.38a11,11,0,0,0-11-11Zm11,49H27v4.4H93Z"></path><rect className="cls-1" x="16" y="16" width="88" height="88"></rect></g></svg>
                  <div className="directoryItem-ba44d"><h2>Internet</h2>
	                 <ul>
						<li><a href="https://www.xfinity.com/support/articles/xfinity-on-campus-getting-started" target="_blank"  rel="noreferrer">What is Xfinity On Campus?</a></li>
						<li><a href="https://www.xfinity.com/support/articles/xfinity-on-campus-connect-to-university-wifi" target="_blank"  rel="noreferrer">Connect To WiFi</a></li>
						<li><a href="https://www.xfinity.com/support/articles/xfinity-on-campus-connect-devices-to-university-wifi" target="_blank"  rel="noreferrer">Connect Devices to WiFi</a></li>
						<li><a href="https://www.xfinity.com/support/articles/xfinity-on-campus-upgrade-internet-speed" target="_blank"  rel="noreferrer">Upgrading Internet Speeds</a></li>
						<li><a href="https://www.xfinity.com/support/articles/xfinity-on-campus-wifi-speed-issues" target="_blank"  rel="noreferrer">Speed Troubleshooting</a></li>
	                  </ul>
                  </div>
              </li>
              <li className="blocks-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="50%" viewBox="0 0 100 100" pointer-events="bounding-box"><defs><style>.cls-1{"{"}fill:none;{"}"}.cls-2{"{"}fill:#8b8b97;{"}"}</style></defs><g transform="translate(-10 0)" id="Layer_2" data-name="Layer 2"><rect className="cls-1" x="16" y="16" width="88" height="88"></rect><path className="cls-2" d="M56.23,47.14l12.64,8.43a1.35,1.35,0,0,1,0,2.24L56.23,66.25a1.35,1.35,0,0,1-2.1-1.12V48.26A1.35,1.35,0,0,1,56.23,47.14ZM74.67,83.83H45.33v4.4H74.67Zm11-45.46H34.33a6.61,6.61,0,0,0-6.6,6.6V68.43a6.61,6.61,0,0,0,6.6,6.6H85.67a6.61,6.61,0,0,0,6.6-6.6V45a6.61,6.61,0,0,0-6.6-6.6m0-4.4a11,11,0,0,1,11,11V68.43a11,11,0,0,1-11,11H34.33a11,11,0,0,1-11-11V45a11,11,0,0,1,11-11Z"></path></g></svg>
					<div className="directoryItem-ba44d">
						
							<h2>TV &amp; Streaming</h2>
						<ul>
							<li><a href="https://www.xfinity.com/support/articles/xfinity-on-campus-upgrade-premium-channels" target="_blank"  rel="noreferrer">Video Upgrades</a></li>
							<li><a href="https://www.xfinity.com/support/articles/xfinity-on-campus-getting-started-with-stream" target="_blank"  rel="noreferrer">Getting Started with Stream</a></li>
							<li><a href="https://www.xfinity.com/support/articles/xfinity-on-campus-instant-tv-troubleshooting" target="_blank"  rel="noreferrer">Instant TV Troubleshooting</a></li>
							<li><a href="https://www.xfinity.com/support/articles/activate-xfinity-tv-app-on-roku" target="_blank" rel="noreferrer">Activate Xfinity Stream on Roku</a></li>
							<li><a href="https://www.xfinity.com/support/articles/xfinity-on-campus-error-codes" target="_blank" rel="noreferrer">Common Error Messages</a></li>
							</ul>
					</div>
				</li>
              <li className="blocks-item">
					<svg width="50%" viewBox="0 0 100 100" pointer-events="bounding-box" xmlns="http://www.w3.org/2000/svg">
					<defs><style>.cls-1{"{"}fill:none;{"}"}.cls-2{"{"}fill:#8b8b97;{"}"}</style></defs><g transform="translate(25 25)" id="Layer_2" data-name="Layer 2"><rect className="cls-1" x="16" y="16" width="88" height="88"></rect><path fill-rule="evenodd" clip-rule="evenodd" d="M36.8332 0.666992H10.1665C4.64366 0.666992 0.166504 5.14414 0.166504 10.667V57.3337C0.166504 62.8565 4.64366 67.3337 10.1665 67.3337H36.8332C42.356 67.3337 46.8332 62.8565 46.8332 57.3337V10.667C46.8332 5.14414 42.356 0.666992 36.8332 0.666992ZM36.8332 5.66699C39.5946 5.66699 41.8332 7.90557 41.8332 10.667V57.3337C41.8332 60.0951 39.5946 62.3337 36.8332 62.3337H10.1665C7.40508 62.3337 5.1665 60.0951 5.1665 57.3337V10.667C5.1665 7.90557 7.40508 5.66699 10.1665 5.66699H36.8332ZM23.8332 41.3337C21.4957 41.3024 19.243 40.4532 17.4665 38.9337L14.7665 42.0337C16.7796 43.8391 19.2874 45.0002 21.9665 45.367V49.0003H26.1332V45.367C29.5139 44.9176 32.0851 42.1076 32.2332 38.7003C32.2332 33.9551 27.8908 32.5107 24.535 31.3944C22.4218 30.6914 20.6998 30.1186 20.6998 28.9337C20.6998 27.7003 22.0332 26.7003 24.2998 26.7003C26.1432 26.706 27.9426 27.2632 29.4665 28.3003L31.6998 24.967C30.0657 23.7471 28.1518 22.9564 26.1332 22.667V19.0003H21.9665V22.567C18.7039 22.8169 16.1421 25.4646 15.9998 28.7337C15.9998 33.2477 19.9181 34.559 23.1804 35.6507C25.5069 36.4293 27.4998 37.0963 27.4998 38.7337C27.4998 40.1337 26.2332 41.3337 23.8332 41.3337Z" fill="#8B8B97"></path>
					</g>
					</svg>
					<div className="directoryItem-ba44d">
						
							<h2>Payment &amp; Subscriptions</h2>
						<ul>
							<li><a href="https://www.xfinity.com/support/articles/xfinity-on-campus-billing-payments" target="_blank"  rel="noreferrer">Billing and Payments</a></li>
							<li><a href="https://www.xfinity.com/support/articles/xfinity-on-campus-refunds-cancellations-moves" target="_blank"  rel="noreferrer">Refunds and Cancellations</a></li>
						</ul>
					</div>
				</li>
      </ul>
   

	</section>
	
</div>
        </div>
    );
    };