import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { FeaturedShows } from './FeaturedShows';
import './Product.css';
export const Product = () => {
    const [content, setContent] = useState<any>(null);
    const [details, setDetails] = useState<any>(null);
    const [productInfo, setProductInfo] = useState<any>(null);
    const [direct, setDirect] = useState<Boolean>(false);
    const auth = localStorage.getItem('authorizationToken') as string;
    // const sessionId = localStorage.getItem('sessionId') as string;
    const query = new URLSearchParams(useLocation().search);
    const productId = query.get('productId');
    const productInfoUrl = '/api/product/' + productId;
    const updateCartUrl = '/api/storefrontproductcart?productId='+productId;
    useEffect(() => {
        fetch(productInfoUrl,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': auth
            }
        })
            .then(async res => {
                if (res.status >= 400) {
                    let err = await res.json();
                    console.error('Error:', err.error);
                } else {
                    let data = await res.json();
                    setProductInfo(data['productInfo']);
                    setDetails(data['details']['details']);
                    setContent(data.global.channelsContent.channels[data.productInfo.Product.identifier]);
                    setDirect(false);
                }
            })
            .catch(error => console.error('Error:', error));
    },[]);
    const addToCart = () => {
        fetch(updateCartUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': auth
            },
            body: JSON.stringify(productInfo)
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                window.location.href = '/checkout';
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const manageSubscription = () => {
        window.location.href = '/account?tab=sub';
    }
    return (
        <section className="product-details-component">
            {productInfo && (
                <div className={`details ${content && (!content.promos || content.promos.length === 0) ? 'text-only' : ''}`}>
                    <div className={`add-package ${productInfo ? '' : 'hidden'}`}>
                        <h2 className={content ? '' : 'hidden'}>
                            {content && content.logo && content.logo.detail ? (
                                <picture>
                                    <source srcSet={`${content.logo.detail.x}, ${content.logo.detail.x2} 2x`} />
                                    <img tabIndex={0} src={content.logo.detail.x} alt={content.logo.alt} />
                                </picture>
                            ) : (<span>{productInfo.Product.Name}</span>)}
                        </h2>

                        <h2 className={productInfo.Product ? 'hidden' : ''}>
                            {productInfo.Product.Name}
                        </h2>

                        <p tabIndex={0}>
                            {content && content.productDescription ? content.productDescription : productInfo.Product.ShortDescription}
                        </p>

                        {productInfo.Available && productInfo.Product.PricingPlans && productInfo.Product.PricingPlans.length > 0 ? (
                            <div tabIndex={0} className="price">
                                {productInfo.Product.PricingPlans[0].Name}
                            </div>
                        ) : null}

                        {productInfo.Available ? (
                            <button className="btn-primary button" data-cta="pkg-subscribe" onClick={addToCart}>
                                {details.package.cta.pkgSubscribe}
                            </button>
                        ) : null}

                        {productInfo.Purchased ? (
                            <button className="btn-primary button" data-cta="manage" onClick={manageSubscription}>
                                {details.package.cta.manage}
                            </button>
                        ) : null}

                        {!direct && !productInfo.Available && !productInfo.Purchased ? (
                            <button className="disabled" disabled={true}>
                                {details.package.cta.disable}
                            </button>
                        ) : null}

                        {direct && !productInfo.Available && !productInfo.Purchased ? (
                            <div>
                                <p>
                                    Before you can stream, looks like we need to set you up with an Xfinity On Campus Stream
                                    account.  Click on the link below to check out what’s offered at your campus!
                                </p>
                                <button className="btn-primary" data-cta="direct">
                                    {details.package.cta.direct}
                                </button>
                            </div>
                        ) : null}

                    </div>
                    {(!content || !content.promos || content.promos.length === 0) && (
                        <p className={content && content.disclaimer && content.disclaimer.length > 0 ? "disclaimer" : ''}>
                            {content && content.disclaimer}
                        </p>
                    )}
                </div>
            )}

            {content && content.promos && content.promos.length > 0 ? (
                <FeaturedShows data={details.featured} content={content} />
            ) : (
                <div className="featured-shows"></div>
            )}

        </section>
    );
}