import './App.css';
import Authenticate from './components/Authenticate';
import { TermsConditions } from './components/termsConditions/TermsConditions';
import { Channels } from './components/channels/Channels';
import { Footer } from './components/footer/Footer';
import { Header } from './components/header/Header';
import { Help } from './components/help/Help';
import { Home } from './components/home/home';
import { Login } from './components/login/Login';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Upgrade } from './components/upgrade/upgrade';
import { Wifi } from './components/wifi/Wifi';
import { Logout } from './components/Logout';
import { Product } from './components/product/Product';
import { WiFiProduct } from './components/wifiproduct/WifiProduct';
import { RadarCheckout } from './components/radarCheckout/RadarCheckout';
import { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { Radar } from './components/radar/radar';
import { Account } from './components/account/Account';
import { Checkout } from './components/checkout/Checkout';
import { ErrorPicker } from './components/error/ErrorPicker';
import { ErrorPage } from './components/error/ErrorPage';

function App() {
  let currentHost = window.location.protocol+ "//" + window.location.hostname ;
    if(window.location.hostname === 'localhost'){
        currentHost = window.location.protocol+ "//" + window.location.hostname +":"+window.location.port;
    }
  // Create script element
  const script = document.createElement('script');
  script.src = process.env.REACT_APP_RADAR_SCRIPT_URL as string;
  const logoutUrl = process.env.REACT_APP_LOGOUT_URL as string;
  // Append script to the document's head
  document.head.appendChild(script);
  const extendSession = async () => {
    if (localStorage.getItem('authorizationToken') != null) {
      const authToken = localStorage.getItem('authorizationToken') as string;
      await fetch('/api/extend/session',
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": authToken
          }
        }).then(async (response) => {
          if (!response.ok) {
            window.location.href = logoutUrl;
            throw new Error('Network response was not ok');
          }else{
            const expireTime = Date.now() + (1000 * 60 * 5); // 5 minutes
            localStorage.setItem('expireTime', expireTime.toString());
          }
        }) 
        .catch((error) => {
          console.error('Error:', error);
          window.location.href = logoutUrl;
        });
    }
  };

  //function to for inactivity and logout
  const checkForInactivity = () =>{
    const expireTime = localStorage.getItem('expireTime')
   //  console.log("Checking checkForInactivity: ", expireTime)
    if(expireTime && Number(expireTime) < Date.now()){
       window.location.href = "/storefrontLogout";
    }
  }
  // useEffect to ser intervel to check for inactivity
  useEffect(() => {
    //check for inactivity
    const intervel = setInterval(()=>{
      console.log('Checking for inactivity');
        checkForInactivity();
    },60000);
    //Clear intervel on ummount
    return () => clearInterval(intervel);
    
  },[]);

  // update expiry time on any user activity
  useEffect(() => {
    extendSession();
    // set Event Listners
    window.addEventListener('click',extendSession)
    window.addEventListener('keypress',extendSession)
    // window.addEventListener('scroll',extendSession)
    // window.addEventListener('mousemove',updateExpireTime)

    // cleanup
    return ()=>{
      window.removeEventListener('click',extendSession)
      window.removeEventListener('keypress',extendSession)
      // window.removeEventListener('scroll',extendSession)
      // window.removeEventListener('mousemove',updateExpireTime)
    }


  },[]);
  const queryParameters = new URLSearchParams(window.location.search);
  
  console.log('window.location.pathname:', window.location.pathname)
  if (window.location.pathname === '/storefrontLogout') {
    console.log('/logout')
    return (
      <Logout />
    )
  } else if (localStorage.getItem('authorizationToken') != null) {
    const authToken = localStorage.getItem('authorizationToken') as string;
    const claims: any = jwtDecode(authToken);
    let partner = claims.partner;
    let isRadar = (claims.radar == undefined) ? true : claims.radar as boolean;
    let termsAccepted = claims["tc-accepted-date"] as boolean;
    console.log('TermsConditionsAccepted:', termsAccepted);
    const xaScript = document.createElement('script');
    xaScript.type = 'module';
    xaScript.crossOrigin = 'anonymous';
    xaScript.src = process.env.REACT_APP_XA_URL as string;
    document.head.appendChild(xaScript);
    return (
      <div className="main-wrapper">
        <Router>
          <Header />
          <Routes>
            {partner.stream && (partner.managedWifi || partner.dpskWifi) ? (
              <>
                <Route path="/home" element={<Home />} />
                <Route path="/" element={<Navigate to="/home" />} />
                <Route path="/login" element={<Navigate to="/home" />} />
                <Route path="/wifi" element={<Wifi />} />
                <Route path="/wifiproduct" element={<WiFiProduct />} />
                {/* <Route path="/channels" element={<Channels />} /> */}
                <Route path="/upgrade" element={<Upgrade />} />
                <Route path="/product" element={<Product />} />
              </>
            ) : partner.stream === false && (partner.managedWifi || partner.dpskWifi) ? (
              <>
                <Route path="/home" element={<Wifi />} />
                <Route path="/wifi" element={<Wifi />} />
                <Route path="/" element={<Navigate to="/wifi" />} />
                <Route path="/login" element={<Navigate to="/wifi" />} />
                <Route path="/wifiproduct" element={<WiFiProduct />} />
              </>
            ) : (
              <>
                <Route path="/home" element={<Home />} />
                <Route path="/" element={<Navigate to="/home" />} />
                <Route path="/login" element={<Navigate to="/home" />} />
                {/* <Route path="/channels" element={<Channels />} /> */}
                <Route path="/upgrade" element={<Upgrade />} />
                <Route path="/product" element={<Product />} />
              </>
            )}

            {/* <Route path="/account" element= {termsAccepted ? <Account /> : <TermsConditions />} /> */}
            <Route path="/radar" element= {termsAccepted ? <Radar /> : <TermsConditions />} />
            <Route path="/account" element= {termsAccepted ?  isRadar ?  <Radar /> : <Account /> : <TermsConditions />} />
            <Route path="/channels" element= {termsAccepted ? <Channels /> : <TermsConditions />} />
            <Route path="/help" element={<Help />} />
            <Route path="/checkout" element={  isRadar ? <RadarCheckout /> : <Checkout/>} />
            <Route path="/terms" element={<TermsConditions />} />
          </Routes>
          <Footer note='my footer' />
        </Router>
      </div>
    );
  } else if ((window.location.pathname === '/jwt' || window.location.pathname === '/sat') && ((queryParameters.has("uuid") && queryParameters.has("uuid_jwt")) || (queryParameters.has("code")))) {
    console.log(window.location.pathname)
    return (
      <div className="main-wrapper">
        <Router>
          <Header />
          <Authenticate />
          <Footer note='my footer' />
        </Router>
      </div>
    )
  } else {
    console.log('else')
    return (
      <div className="main-wrapper">
        <Router>
          <Header />
          <Routes >
            <Route path="/login" element={<Login />} />
            <Route path="/terms" element={<TermsConditions />} />
            <Route path="*" element={<Navigate to="/login" />} />
            <Route path="/pickererror" element={<ErrorPicker/>} />
            <Route path="/errorPage" element={<ErrorPage/>} />
          </Routes>
          <Footer note='my footer' />
        </Router>
      </div>
    );
  }
}
export default App;
