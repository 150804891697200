import { Link } from "react-router-dom";

export const ChannelServices = () => {
    const channelServices = JSON.parse(localStorage.getItem('channelServices') as string);
    const globalContent = JSON.parse(localStorage.getItem('globalContent') as string);
    const channelsContent = globalContent.global.channelsContent.channels;
    if (channelServices.premium !== undefined && channelServices.premium.length > 0) {
        const primiumServices = channelServices.premium;
        return (
                    <div className="tile-grid">
                        {primiumServices.map((channel: any, index: number) => {
                            let channelContent = channelsContent[channel['identifier']];
                            let backGround = channelContent['background'];
                            let logo = channelContent['logo'] ? channelContent['logo'] : '';
                            let logoPremium =  channelContent['logo'] ? logo['premium'] : '';
                            return (
                            <Link to={'/product?productId=' + channel['Id']} key={index} aria-label="HBO package" className={"tile "+channel['identifier']}>
                                <picture className="bg-img">
                                    <source media="(min-width: 768px)" srcSet={backGround['l'] + ", "+backGround['l2']+" 2x"} />
                                    <source srcSet={backGround['s'] +", "+backGround['s2']+" 2x"} />
                                    <img src={backGround['s']} alt={backGround['alt']} data-cookiecategory="1210" />
                                </picture>
                                <div className="tile-content">
                                {logo !== '' ? (
                                    <picture className="logo">
                                        <source srcSet={logoPremium['x'] + ", "+logoPremium['x2']+" 2x"}  />
                                        <img src={logoPremium['x']} alt={logo['alt']} data-cookiecategory="1210" />
                                    </picture>
                                    ) : (
                                    <div className="heading">{channelContent['title']}</div>
                                )}
                                    <p className="tile-copy">{channelContent['copy']}</p>
                                    <span className="tile-link">Pricing &amp; Other Info</span>
                                </div>
                            </Link>
                        )})}
                    </div>
        );
    }else{
        return null;
    }
}