export const Logout = () => {
    // cleanup local storage
    localStorage.removeItem('user');
    localStorage.removeItem('contentDirectSession');
    localStorage.removeItem('globalContent');
    localStorage.removeItem('channelServices');
    localStorage.removeItem('partner');
    localStorage.removeItem('wifiBundle');
    localStorage.removeItem('sessionId');
    localStorage.removeItem('xerxesToken');
    localStorage.removeItem('authorizationToken');
    localStorage.removeItem('expireTime');
    console.log('logout susccessful');
    window.location.href = "/login";
    return (
        <main className="main-content" role='main'>
        <section className="hero-component logged-in">
          <h2>Xfinity On Campus</h2>
          <p>Signing out please wait ...</p>
        </section>
      </main>
    )
}