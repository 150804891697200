import './ErrorPage.css';
export const ErrorPage = () => {
    return (
        <div className="xoc-content">
				<div className="xhead">
					<h2 className="tophead" style={{fontSize: "30px"}}>Please contact
						your Help Desk or IT Helpline for assistance.</h2>
				</div>
				<div className="xoc-content">
					<h3>Something has gone wrong.</h3>
					<br/>
					<br/> <img alt="Comcast" height="271" src="./resources/images/devices.png" width="450" />
				</div>
			</div>
    );
}