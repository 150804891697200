import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './WifiProduct.css';
export const WiFiProduct = () => {
    const [content, setContent] = useState<any>(null);
    const [details, setDetails] = useState<any>(null);
    const [productInfo, setProductInfo] = useState<any>(null);
    const [direct, setDirect] = useState<Boolean>(false);
    const auth = localStorage.getItem('authorizationToken') as string;
    const query = new URLSearchParams(useLocation().search);
    const productId = query.get('productId');
    const planId = query.get('planId');
    const productInfoUrl = '/api/product/' + productId +'?planId=' +planId;
    const updateCartUrl = '/api/storefrontproductcart?productId='+productId;
    useEffect(() => {
        fetch(productInfoUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': auth
            }
        })
            .then(async res => {
                if (res.status >= 400) {
                    let err = await res.json();
                    console.error('Error:', err.error);
                  } else {
                    let data = await res.json();
                    console.log('data:', data);
                    setProductInfo(data['productInfo']);
                    console.log('productInfo:', productInfo);
                    setDetails(data['details']['details']);
                    console.log('details:', details);
                    setContent(data.global.channelsContent.channels[data.productInfo.Product.identifier]);
                    console.log('Content:', content);
                    setDirect(false);
                  }
            })
            .catch(error => console.error('Error:', error));
    });
    const addToCart = () => {
        fetch(updateCartUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': auth
            },
            body: JSON.stringify(productInfo)
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                window.location.href = '/checkout';
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const manageSubscription = () => {
        window.location.href = '/account?tab=sub';
    }
    return (
        <section className="product-details-component">
            {productInfo && (
                <div className={`details ${content && (!content.promos || content.promos.length === 0) ? 'text-only' : ''}`}>
                    <div className={`add-package ${productInfo ? '' : 'hidden'}`} >
                        <div style={{display:'flex'}}>
                        <div style={{flex: "2"}}>
                            <h2 className={productInfo.Product ? 'hidden' : ''}>
                                {productInfo.Product.Name}
                            </h2>
                            <p tabIndex={0} dangerouslySetInnerHTML={{ __html: content && content.productDescription ? content.productDescription : '</p>' + productInfo.Product.ShortDescription + "<p>" }} />

                            {productInfo.Available  ? (
                                <button className="btn-primary button" data-cta="pkg-subscribe" onClick={addToCart}>
                                    {details.package.cta.pkgSubscribe}
                                </button>
                            ) : null}

                            {productInfo.Purchased   ? (
                                <button className="btn-primary button" data-cta="manage" onClick={manageSubscription}>
                                    {details.package.cta.manage}
                                </button>
                            ) : null}

                            {!direct && !productInfo.Available && !productInfo.Purchased   ? (
                                <button className="disabled" disabled={true}>
                                    {details.package.cta.disable}
                                </button>
                            ) : null}

                            <p>
                                Please note that WiFi speed upgrades may take up to 24 hours to take effect.
                            </p>
                        </div>
                        <h2 style={{flex: "3"}}>
                            <div>
                                <picture>
                                    <img style={{width: '611px'}} tabIndex={0} src="/library/images/manwithhedge.png" alt="Man near hedge"/>
                                </picture>
                            </div>
                        </h2>
                        </div>
                    </div>
                    {(!content || !content.promos || content.promos.length === 0) && (
                        <p className={content && content.disclaimer && content.disclaimer.length > 0 ? "disclaimer" : ''}>
                            {content && content.disclaimer}
                        </p>
                    )}
                </div>
            )}
        </section>
    );
}