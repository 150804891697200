import { Link } from 'react-router-dom';
import "./InternetServices.css";
export const InternetServices = () => {
    if (localStorage.getItem('wifiBundle')) {
        const wifiBundle = JSON.parse(localStorage.getItem('wifiBundle') as string);
        const product = wifiBundle.Product;
        // const globalContent = JSON.parse(localStorage.getItem('globalContent') as string);
        // const grid = globalContent.upgrade.grid;
        // let newThumbnailUrl = product.ThumbnailUrl.replace('/resources/images/common/', '/library/images/common/');
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }} >
                <h1 style={{ color: "#6138f5", marginTop: "40px" }}>Speeds and Prices</h1>
                <div style={{ margin: "20px" }} >
                    <div className="content-wrapper c1-wrapper" >
                        <img style={{ width: "92px" }} alt="wifi logo" src="/library/images/wifi/wifi_logo.png" data-cookiecategory="1210" />
                        <h1>200 Mbps</h1>
                        <h2 className="c1-wrapper-header">Base Speed</h2>
                    </div>
                    {product.PricingPlans.map((pricingPlan: any, index: number) => {
                        return (
                            <div key={index} className="content-wrapper c1-wrapper" >
                                <img style={{ width: "92px" }} alt="wifi logo" src="/library/images/wifi/wifi_logo.png" data-cookiecategory="1210" />
                                <h1>{pricingPlan.Name}</h1>
                                <h2 className="c1-wrapper-header">{pricingPlan.chargeAmountLabel}</h2>
                                <Link to={`/wifiproduct?productId=${product.Id}&planId=${pricingPlan.Id}`} className="btn-primary" style={{ backgroundColor: "#6138f5" }} aria-label="600 Mbps">
                                    Learn More
                                </Link>
                            </div>
                        )
                    })}
                    <div className="clear"></div>
                </div>
                <p className="wifiFaq">
                        If you previously purchased an upgrade and would like to make a change to an alternate speed,<br />
                        you will need to first cancel your current speed subscription.  Please visit <a href="https://support.xfinityoncampus.com/">WiFi FAQ</a>.
                </p>
            </div>
        );
    } else {
        return null;
    }
}