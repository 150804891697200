
import 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "./Order.css";
import "./csgiaccount.css";
import { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
declare global {
    interface Window {
        ascendon: any;
    }
}

interface Dpsk {
    ssid: string;
    key: string;
    qrCode: string;
};
/* eslint-disable jsx-a11y/anchor-is-valid */
export const Account = () => {
    const styles = {
        flexAuto: {
            flex: "1 1 auto"
        },
        paddingLeftRight: {
            paddingLeft: "8%",
            paddingRight: "8%"
        },
        borderPaddingMargin: {
            border: "1px solid #ddd",
            paddingLeft: "15px",
            marginRight: "15px"
        },
        margin: {
            marginTop: "10px",
            marginBottom: "10px",
            marginRight: "10px"
        },
        paddingMarginBorder: {
            paddingLeft: "15px",
            paddingTop: "15px",
            paddingBottom: "15px",
            marginRight: "15px",
            border: "1px solid #ddd"
        },
        marginBottom: {
            marginBottom: "5px"
        },
        monospaceFont: {
            fontFamily: "monospace",
            fontSize: "16pt"
        },
        inlineBlock: {
            verticalAlign: "top",
            display: "inline-block",
            textAlign: "center",
            width: "150px"
        },
        imageStyle: {
            width: "120px",
            height: "120px"
        },
        boldText: {
            display: "block",
            fontWeight: "bold",
            padding: "10px"
        }
    };
    const authToken = localStorage.getItem('authorizationToken') as string;
    const claims: any = jwtDecode(authToken);
    const partner = claims.partner;
    const logoutUrl = process.env.REACT_APP_LOGOUT_URL as string;
    // const [account, setAccount] = useState([]);
    const [dpskRenewDialog, setDpskRenewDialog] = useState(false);
    interface Dpsk {
        ssid: string;
        wpa_key: string;
        qrCode: string;
    }
    
    const [dpsk, setDpsk] = useState<Dpsk>({ ssid: 'ssid', wpa_key: 'key', qrCode: 'qrCode' });
    const [accountTab, setAccountTab] = useState(true);
    const [products, setProducts] = useState(null);
    const [xaWidgetUrl, setXaWidgetUrl] = useState(null);
    const [copiedText, setCopiedText] = useState("Not Copied");
    const [dpskShow, setDpskShow] = useState(false);
    useEffect(() => {
        fetch('/api/csgi/settings', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authToken
            }
        })
            .then(response => response.json())
            .then(data => {
                window.ascendon.service.settings.sessionId = data.sessionId;
                window.ascendon.service.settings.systemId = data.systemId;
                window.ascendon.service.settings.channelId = data.channelId;
                window.ascendon.service.settings.apiUrl = data.apiUrl;
                window.ascendon.service.settings.metadataUrl = data.metadataUrl;
                window.ascendon.service.settings.deviceType = data.deviceType;
                window.ascendon.service.settings.language = data.language;
                window.ascendon.accountManagement.configure({
                    container: 'accountManagementDiv',
                    resourceLanguage: data.language
                });
                $('#accountManagementDiv').show();
                window.ascendon.accountManagement.showAccount();
            })
            .catch(error => {
                console.log(error);
                window.location.href = logoutUrl;
            });

        if (partner.dpskWifi === true) {
            fetch('/api/dpsk', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authToken
                }
            })
            .then(async response => {
                if (response.status >= 400) {
                    let err = await response.json();
                    console.error('Error:', err.error);
                } else {
                    let data = await response.json();
                    setDpsk(data);
                    setDpskShow(true);
                }
            }).catch(error => {
                console.log(error);
                window.location.href = logoutUrl;
            });
        }

    },[]);
    const onClickAccountBtn = () => {
        $('#accountManagementDiv').show();
        window.ascendon.accountManagement.showAccount();
        setAccountTab(true);
    };
    const onClickPaymentBtn = () => {
        $('#accountManagementDiv').show();
        window.ascendon.accountManagement.showPayment();
        setAccountTab(false);
    }
    const onClickOrderHistoryBtn = () => {
        $('#accountManagementDiv').show();
        window.ascendon.accountManagement.showOrderHistory();
        setAccountTab(false);
    }
    const onClickSubscriptionsBtn = () => {
        $('#accountManagementDiv').show();
        window.ascendon.accountManagement.showSubscriptions();
        setAccountTab(false);
    }
    const onClickRenewDpsk = () => {
        setDpskRenewDialog(true);
    }
    const onClickDialogNo = () => {
        setDpskRenewDialog(false);
    }

    const onClickGenerateDpsk = () => {
        console.log('onClickGenerateDpsk');
        $('#dpskRenewDialog').modal('show');
        $('#beforeClick').show();
        $('#modalSpinner').hide();
        $('#successRenew').hide();
        $('#errorRenew').hide();
    }

    const copyToClipboard = (text:string) => {
        navigator.clipboard.writeText(text).then(() => {
          // Optionally, implement feedback to the user that the text was copied.
          console.log('Password copied to clipboard!');
        }).catch(err => {
          // Handle any errors
          console.error('Failed to copy text: ', err);
        });
      };

    const onClickDialogYes = () => {
        $('#beforeClick').hide();
        $('#modalSpinner').show();
        fetch('/api/dpsk/reset', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authToken
            }
        })
            .then(response => response.json())
            .then(data => {
                setDpsk(data);
                $('#modalSpinner').hide();
                $('#successRenew').show();
            })
            .catch(error => {
                console.log(error);
                $('#modalSpinner').hide();
                $('#errorRenew').show();
            });
    }

    window.ascendon.service.events.on('error', function (event: any, err: any) {
        if (err.Code === 109) {
            
            if (logoutUrl) {
                window.location.href = logoutUrl;
            }
        }
    });
    return (
        <div ng-view className="ng-scope">
            <div id="accountcontent">
                <div id="accountcontentmain" className="ascendon accoutnDiv">
                    <div style={{ paddingLeft: "8%", paddingRight: "8%" }}>
                        <div className="row">
                            <div className="col-lg-12">
                                <h1 className="subhead">
                                    <span>Your Account Settings</span>
                                </h1>
                                <div className="headerline"></div>
                            </div>
                        </div>

                        <div id="accountTab" className="tabbable tabs-left">
                            <ul className="nav nav-tabs">
                                <li className={accountTab ? 'active' : ''}><a id="accountBtn" data-toggle="tab" href="#" onClick={onClickAccountBtn}>Profile</a></li>
                                <li><a id="paymentBtn" data-toggle="tab" href="#" onClick={onClickPaymentBtn}>Payment</a></li>
                                <li><a id="orderHistoryBtn" data-toggle="tab" href="#" onClick={onClickOrderHistoryBtn}>Order History</a></li>
                                <li><a id="subscriptionsBtn" data-toggle="tab" href="#" onClick={onClickSubscriptionsBtn}>Subscriptions</a></li>
                            </ul>
                            <div className="tab-content warptabcontent">
                                <div id="accountManagementDiv"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={dpskShow === false ? { ...styles.flexAuto, display: 'none' } : styles.flexAuto}>
                    <div style={styles.paddingLeftRight}>
                        <div style={styles.borderPaddingMargin}>
                            <h2 style={{ fontFamily: "'XfinityStandardBold', Helvetica, Arial, sans-serif", fontWeight: 700, fontSize: "22px" }}>
                                WiFi Information
                            </h2>
                            <div style={styles.margin}>
                                <div style={styles.paddingMarginBorder}>
                                    <label>Resident Network</label>
                                    <div style={{ ...styles.marginBottom, ...styles.monospaceFont }}>{dpsk.ssid}</div>
                                </div>
                            </div>
                            <div style={styles.margin}>
                                <div style={styles.paddingMarginBorder}>
                                    <label>Password</label>
                                    <div style={{ ...styles.marginBottom, ...styles.monospaceFont }}>{dpsk.wpa_key}
                                    <button onClick={() => copyToClipboard(dpsk.wpa_key)} style={{ marginLeft: '10px' }}>
                                        Copy
                                    </button>
                                    </div>
                                    <div style={{ marginBottom: "5px"}}><a id="renewDpsk" onClick={onClickGenerateDpsk}>Generate new password</a></div>
                                </div>
                            </div>
                            <div style={{ ...styles.margin, textAlign: "left" }}>
                                <div style={styles.paddingMarginBorder}>
                                    <div style={{
                                        verticalAlign: "top",
                                        display: "inline-block",
                                        textAlign: "center",
                                        width: "150px"
                                    }}>
                                        <img style={styles.imageStyle} src={dpsk.qrCode} />
                                        <span style={styles.boldText}>Scan to connect</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="dpskRenewDialog" data-backdrop="static" style={{display: "none"}}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" id="beforeClick">
                        <div className="modal-header">
                            <button type="button" className="close" aria-hidden="true" onClick={() => {$('#dpskRenewDialog').modal('hide');}}>&times;</button>
                            <h4 className="modal-title">Are you sure?</h4>
                        </div>
                        <div id="modalBody" className="modal-body"> 
                            Refreshing your password will require re-entering your new password on all your currently connected devices.
                        
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-primary btn-lg" id="dialogNo" onClick={() => {$('#dpskRenewDialog').modal('hide');}}>No</a>
                            <a type="button" style={{ marginLeft: "5px"}} className="btn btn-primary btn-lg"  id="dialogYes" onClick={onClickDialogYes}>Yes</a>
                        </div>
                    </div>
                    <div className="modal-content" id="modalSpinner" style={{display: "none", height: "300px", textAlign: "center", paddingTop: "120px"}}>
                        <div id="modalBody" className="modal-body"> 
                            <i className='fa fa-spinner fa-pulse' style={{fontSize: "3em"}}></i>
                            Generating new password...
                        </div>
                    </div>
                    <div className="modal-content" id="successRenew" style={{display: "none"}}>
                        <div className="modal-header">
                            <button type="button" className="close" aria-hidden="true" onClick={() => $('#dpskRenewDialog').modal('hide')}>&times;</button>
                            <h4 className="modal-title" style={{textAlign: "center"}}><i className='fa fa-check-circle-o channelavailable'></i> Password Refreshed!</h4>
                        </div>
                            <div  className="modal-body" style={{textAlign: "center"}}>To connect using the new password, forget the network in your device’s 
                            WiFi settings. Wait 1 minute before connecting with the new password.</div>
                        <div id="modalBody" className="modal-body center" style={{textAlign: "center"}}> 
                            <label>New Password</label>
                            <div style={{marginBottom: "5px", fontFamily: "monospace", fontSize: "16pt"}}>{dpsk.wpa_key} <a href="#" id="copyPwd"><i className='fa fa-clipboard'></i></a><br/>
                        </div>									
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-primary btn-lg" onClick={() => $('#dpskRenewDialog').modal('hide')}>Ok</a>
                        </div>
                    </div>
                    <div className="modal-content" id="errorRenew" style={{display: "none"}}>
                        <div className="modal-header">
                            <button type="button" className="close" aria-hidden="true" onClick={() => $('#dpskRenewDialog').modal('hide')}>&times;</button>
                            <h4 className="modal-title" style={{textAlign: "center"}}><i className='fa fa-times-circle channelunavailable'></i> Error</h4>
                        </div>
                        <div id="modalBody" className="modal-body"> 
                            <div style={{marginBottom: "5px"}}>Your password was unable to be refreshed. Please try again.</div>									
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-primary btn-lg" onClick={() => $('#dpskRenewDialog').modal('hide')}>Cancel</a>
                            <a type="button" className="btn btn-primary btn-lg" id="dialogErrorYes">Try Again</a>
                        </div>
                    </div>
                </div>
          	</div>
            </div>
        </div>
    );
};