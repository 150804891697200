import React, { FC } from 'react';

interface FeaturedShowsProps {
  data: any;
  content: any;
}

export const FeaturedShows: FC<FeaturedShowsProps> = ({ data, content }) => {
  return (
    <div className="featured-shows">
      <h2 tabIndex={0}>{data.heading}</h2>

    {content.promos.map((promo: any, index: number) => (
        <div key={index} className={content.promos.length > 2 && index > 0 ? 'secondary' : 'primary'}>
          {promo.type === 'image' && promo.image && (
            <picture tabIndex={0}>
              <source srcSet={`${promo.image.l}, ${promo.image.l2} 2x`} />
              <img src={promo.image.l} alt={promo.image.alt} />
            </picture>
          )}

          {promo.type === 'video' && promo.video && (
            <div tabIndex={0} className="embed-container">
              <iframe src={promo.video.link} frameBorder="0" allowFullScreen title='video'></iframe>
            </div>
          )}
        </div>
      ))}

      <p className="disclaimer" tabIndex={0}>{content.disclaimer}</p>
    </div>
  );
};