import { Link } from "react-router-dom";

export const InternationalServices = () => {
    const channelServices = JSON.parse(localStorage.getItem('channelServices') as string);
    const globalContent = JSON.parse(localStorage.getItem('globalContent') as string);
    const grid = globalContent.upgrade.grid;
    if (channelServices.international !== undefined && channelServices.international.length > 0) {
        return (
            <section className="grid-component home international">
                <div className="inner-column">
                    <h2>{grid.international.heading}</h2>
                    <div className="tile-grid">
                    {channelServices.international.map((channel: any, index: number) => {
                        let newThumbnailUrl = channel.ThumbnailUrl.replace('/resources/images/common/', '/library/images/common/');
                        return(
                                <Link to={"/product?productId=" + channel.Id} key={index} className="tile" aria-label={channel.Name +" channel"}  >
                                    <img alt="" className="placeholder" src="/library/images/grid/international-bkgd.png" data-cookiecategory="1210" />
                                    <div className="content-wrapper">
                                        <img className="logo" alt={channel.Name + "logo"} src={newThumbnailUrl} data-cookiecategory="1210" />
                                        <span className="tile-link">{channel.Name}</span>
                                    </div>
                                </Link>
                    )})}
                    </div>
                </div>
            </section>
        )
    } else {
        return null;
    }
}