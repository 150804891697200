import { ChannelServices } from "../channelServices/ChannelServices";
import { InternationalServices } from "../internationalServices/InternationalServices";
import "./Upgrade.css";
export const Upgrade = () => {
    const globalContent = JSON.parse(localStorage.getItem('globalContent') as string);
    const upgrade = globalContent.upgrade.hero;
    return (
        <div id="main-content" role="main">
            <section className={"hero-component upgrade"}>
                <span className="eyebrow">{upgrade.eyebrow}</span>
                <h2>{upgrade.heading}</h2>
                <p>{upgrade.copy}</p>
            </section>
            <section className="grid-component upgrade premium">
                <div className="inner-column">
                    <h2>Premium Channels</h2>
            <ChannelServices />
            </div>
            </section>
            <InternationalServices />
        </div>
    );
}