import "./TermsConditions.css";
import React, { useState, MouseEvent } from 'react';
// import { jwtDecode } from 'jwt-decode';
// import { useNavigate } from "react-router-dom";

export const TermsConditions = () => {
  let continueEnabled = "pa-btn btn-warning btn-lg";
  let continueDisabled = "pa-btn btn-warning btn-lg disabled";
  const [classes, setClasses] = useState(continueDisabled);
  // const navigate = useNavigate();

  const changeClass = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.checked);
    if (event.target.checked) {
      setClasses(continueEnabled);
    } else {
      setClasses(continueDisabled);
    }
  };

  function continueClicked(event: MouseEvent<HTMLAnchorElement>): void {
    event.preventDefault();

    if (classes === continueEnabled) {

      let authorizationToken = localStorage.getItem('authorizationToken') as string;

      fetch('/api/termsAccept',
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': authorizationToken
          }
        })
        .then(response => {
          return response.text();
        })
        .then(authToken => {
          localStorage.setItem('authorizationToken', authToken);
          // const claims: any = jwtDecode(authToken);
          // let termsAccepted = claims["tc-accepted-date"] as boolean;
          // console.log('TermsConditionsAccepted: [%]', termsAccepted);
          window.location.href= './home';
        })
        .catch(error => {
          console.error('Unable to accept terms & conditions: ', error);
        });
    }
  }

  return (
    <main id="main-content" role="main">
      <section className={""}>
        <div className="modal-footer-terms">
          <div className="popupcheckctrls-terms">
            <div id="terms-checbx">
              <input id="accept" name="accept" type="checkbox" onChange={changeClass} />
              <label htmlFor="accept"> Do you accept the <a target="_blank" href="https://www.xfinity.com/Corporate/Customers/Policies/SubscriberAgreement" rel="noopener noreferrer"><span id="activate-text">Terms of Activation?</span></a></label>
            </div>
          </div>
          <a id="termsContinue" className={classes} onClick={continueClicked}>Continue </a>
        </div>
      </section>
    </main>
  );
}